import React, { useContext, useState } from 'react'
import "./ImagePreview.css"
import { ProductContext } from '../SecondLanding/SecondLandingPage';

const ImagePreview = (props: any) => {
  let val: any = useContext(ProductContext);
  
  return (
    <div className='image-preview'>
      <img className='image'
         src={`https://${val.imageUrl}`}
        // src={require(`${props.source}`) ?? ""}
        alt="" />
    </div>
  )
}

export default ImagePreview
