/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./ProductNav1.css";
// import image1  from "./image1.jpg"
import { KeyboardArrowDown } from "@mui/icons-material";
import MoreDropDown from "../ZonekartComps/MoreDropDown";

let style = {
  color: "gray",
  fontSize: "15px",
  backgroundColor: "rgb(255, 255, 255)",
};
const ProductNav1 = () => {
  return (
    <div className="product-nav-m">
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/flap/128/128/image/29327f40e9c4d26b.png?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span>Grocery</span>
      </div>
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/flap/128/128/image/22fddf3c7da4c4f4.png?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span>Mobiles</span>
      </div>
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/fk-p-flap/128/128/image/0d75b34f7d8fbcb3.png?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span>
          {" "}
          Fashion <KeyboardArrowDown className="icon-m" style={style} />
        </span>
        <div className="more-dropdown-m">
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/flap/128/128/image/69c6589653afdb9a.png?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span>
          Electronics <KeyboardArrowDown className="icon-m" style={style} />
        </span>{" "}
        <div className="more-dropdown-m">
          <MoreDropDown />
        </div>{" "}
      </div>
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/flap/128/128/image/ab7e2b022a4587dd.jpg?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span>
          {" "}
          Home
          <KeyboardArrowDown className="icon-m" style={style} />
        </span>{" "}
        <div className="more-dropdown-m">
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/flap/128/128/image/0ff199d1bd27eb98.png?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span> Appliances</span>
      </div>
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/flap/128/128/image/71050627a56b4693.png?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span>Travel</span>{" "}
      </div>
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/flap/128/128/image/f15c02bfeb02d15d.png?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span>Top Offers</span>
      </div>
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/flap/128/128/image/dff3f7adcf3a90c6.png?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span>
          Beauty, Toys & More
          <KeyboardArrowDown className="icon-m" style={style} />
        </span>{" "}
        <div className="more-dropdown-m">
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type-m">
        <img
          className="img-nav-m"
          src={
            "https://rukminim1.flixcart.com/fk-p-flap/128/128/image/05d708653beff580.png?q=100"
          }
          width={"65px"}
          height={"65px"}
        />
        <span>
          Two Wheelers
          <KeyboardArrowDown className="icon-m" style={style} />
        </span>{" "}
        <div className="more-dropdown-m">
          <MoreDropDown />
        </div>
      </div>
    </div>
  );
};

export default ProductNav1;
