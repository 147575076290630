import React, { createContext } from "react";
import "./SecondLandingPage.css";
import ViewComponent from "../ViewComponet/ViewComponent";
import ProductDetails from "../ProductDiscription/ProductDetails";
import { useLocation } from "react-router-dom";
import ProductNav from "../../ProductNav/ProductNav";

export const ProductContext = createContext(null);
const SecondLandingPage = () => {
  let { state } = useLocation();

  return (
    <div className="page-landing">
      <ProductNav />
      <div className="item-showcase">
        <ProductContext.Provider value={state}>
          <div className="view">
            <ViewComponent />
          </div>
          <div className="prod-disc">
            <ProductDetails />
          </div>
        </ProductContext.Provider>
      </div>
    </div>
  );
};

export default SecondLandingPage;
