import React, { useState } from "react";
import "./ProductNav.css";
import { KeyboardArrowDown } from "@mui/icons-material";
import MoreDropDown from "../ZonekartComps/MoreDropDown";

const ProductNav = () => {
  
  // function convertDate(inputFormat) {
    //   function pad(s) { return (s < 10) ? '0' + s : s; }
    //   var d = new Date(inputFormat)
    //   return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    // }
    // console.log(convertDate('05-Apr-23'))
    let style = { color: "gray"  ,fontSize:"15px",  backgroundColor: "rgb(255, 255, 255)"
    } 
  
  return (
    <div className="product-nav">
      <div className="products-type">
        <span>Electronics</span><KeyboardArrowDown className="icon"  style={{...style,}} />
        <div  className="more-dropdown">
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type">
        <span>TVs & Appliances</span>  <KeyboardArrowDown className="icon" style={style} />
        <div  className="more-dropdown">
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type">
        <span> Men </span> <KeyboardArrowDown className="icon" style={style} />
        <div  className="more-dropdown">
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type">
        <span>Women</span>   <KeyboardArrowDown className="icon" style={style} />
        <div  className="more-dropdown">
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type">
        <span> Baby & Kids </span> <KeyboardArrowDown className="icon" style={style} />
        <div  className="more-dropdown" >
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type">
        <span> Home & Furniture </span><KeyboardArrowDown className="icon" style={style} />
        <div  className="more-dropdown">
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type">
        <span>Sports,Books & More </span> <KeyboardArrowDown className="icon" style={style} />
        <div  className="more-dropdown">
          <MoreDropDown />
        </div>
      </div>
      <div className="products-type"><span>Flights</span></div>
      <div className="products-type"><span>Offer Zone</span></div>
    </div>
  );
};

export default ProductNav;
