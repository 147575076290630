import React, { useEffect, useMemo, useState } from "react";
import "./ShowCard.css";
import { StarRateSharp } from "@mui/icons-material";
import { Link } from "react-router-dom";

const ShowCard = (prop: any) => {
  const [percent, setPercent] = useState(
    (Math.random() * 90).toFixed(0)
  ) as any;
  const { props } = prop;

  let colors = ["red", "yellow", "skyblue", "green", "blue", "aqua"];
  let images = [
    "./image1.jpg",
    "./img1.jpg",
    "./img2.jpg",
    "./img3.jpg",
    "./img4.jpeg",
    "./img5.jpg",
    "./img6.jpeg",
    "./img7.jpg",
  ];

  let val = props.facetGroupings[0]?.products;
  let length = val?.length ?? 0;
  let arr = new Array(length).fill(0);

  
  return (
    <div  className={prop.className }>
      <div className="card">
        <Link to="/productview" state={props}>
          <img
            className="product-image"
            src={`https://${props.imageUrl}`}
            // src={require(`${
            //   images[Math.floor(Math.random() * images.length)]
            // }`)}
            alt={""}
            width={"200px"}
            height={"150px"}
          />
          <a href={props.url} title={props.name} className="product-details">
            {props.name}
          </a>
          <div className="color-features">
            {props.colour}, {props.brandName}
          </div>
          <div className="review-container">
            <span className="product-rate">
              {Number(Math.random() * (3 - 5 + 1) + 3).toFixed(1)}
              <StarRateSharp className="star" />
            </span>
            <span className="rate-count">
              ({Number(Math.ceil(Math.random() * 1000000)).toFixed(0)})
            </span>
          </div>
          <div className="price-case">
            <span style={{ color: "black" }} className="current-price">
              ₹{" "}
              {(
                Number(props.price.current.value * 84) -
                (Number(props.price.current.value * 84) / 100) * percent
              ).toFixed(0)}
            </span>
            <span className="old-price">
              <s> ₹ {(props.price.current.value * 84).toFixed(0)}</s>
            </span>
            <span className="product-offer">{percent}% off</span>
          </div>
          <div style={{ color: "black" }} className="delivery-type">
            Free Delivery
          </div>
        </Link>

        <div className="offers"> Bank Offer</div>
        <div className="on-hover">
          <span style={{ color: "black" }} className="compare">
            <input value={props.id} type="checkbox" onChange={prop.addToCompare} />
            Add to Compare
          </span>
          <section>
            {arr.map((color: any, index: any) => (
              <span
                key={index}
                className="color-selection"
                style={{
                  backgroundColor:
                    colors[Math.floor(Math.random() * colors.length)],
                }}
              ></span>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default ShowCard;
