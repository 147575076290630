import React from "react";

const KeyIterate = () => {
  return (
    <div style={{ fontSize: "12px", fontWeight: "600" }} className="item-keys">
      <div className="item-key-image">{""}</div>
      <div className="item-key">{"Name"}</div>
      <div className="item-key">{"Rating & Reviews Type"}</div>
      <div className="item-key">{"Brand Name"}</div>
      <div className="item-key">{"Delivery Type"}</div>
      <div className="item-key">{"Price"}</div>
      <div className="item-key">{"Offers"}</div>
      <div className="item-key">{"Colour"}</div>
      <div className="item-key">{"Colour Variants"}</div>
      <div className="item-key">{"Fast Delivery"}</div>
    </div>
  );
};

export default KeyIterate;
