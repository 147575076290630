import React, { useContext } from "react";
import "./ImageGallery.css";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ProductContext } from "../SecondLanding/SecondLandingPage";
const ImageGallery = (props: any) => {
  let val: any = useContext(ProductContext);

  return (
    <div className="main-contain">
      <div className="image-container">
        {/* {props.imageData.map((url: any, index: any, data: any) => ( */}
          <img
            className="image-"
            // key={index}
            onMouseOver={props.clicks}
            src={`https://${val.imageUrl}`}
            // src={require(`${url}`)}
            alt={`${val.imageUrl}`}
            width={"80px"}
            height={"80px"}
          />
        {/* ))} */}
      </div>
      <button className="scroll-button">
        <KeyboardArrowDown />
      </button>
    </div>
  );
};

export default ImageGallery;
