import React, { useState } from 'react'
import "./ProductView.css"
import ImageGallery from '../ImageGallery/ImageGallery'
import ImagePreview from '../ImagePreview/ImagePreview';

const ProductView = () => {
  const [source, setsource] = useState("./img1.jpg") as any

  const fb = (event:any) => {
    const src =event.target.getAttribute("alt")
      setsource(src)
  }
  let imageData=["./img1.jpg","./img2.jpg","./img3.jpg","./img4.jpeg","./img5.jpg","./img6.jpeg","./img8.jpeg","./img9.jpg"]

  return (
    <div className='container'>
      <ImageGallery clicks={fb} imageData={imageData} />
      <ImagePreview source={source } />
    </div>
  )
}

export default ProductView