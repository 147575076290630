import React from 'react'
import "./SideNav.css"

const SideNav = () => {
  return (
      <aside className='aside-Nav show'>
          <div className='head'></div>
    </aside>
  )
}

export default SideNav