import React, { useContext, useState } from "react";
import "./ProductDiscription.css";
import { StarRateSharp } from "@mui/icons-material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { ProductContext } from "../SecondLanding/SecondLandingPage";

const ProductDiscription = () => {
  const [percent, setPercent] = useState(
    (Math.random() * 90).toFixed(0)
  ) as any;
  const [size, setsize] = useState(10);
  let val: any = useContext(ProductContext);

  let newArr = [];
  for (let i = 0; i < size / 2; i++) {
    newArr.push(i);
  }

  const seeMoreOffers = () => {
    if (size !== 20) {
      setsize(20);
    } else {
      setsize(10);
    }
  };
  return (
    <div className="product-discript">
      <h3 className="head-namea">{val.name}</h3>
      <div className="review-container-com ">
        <span className="product-rate-com ">
          {Number(Math.random() * (3 - 5 + 1) + 3).toFixed(1)}
          <StarRateSharp className="star-com " />
        </span>
        <span className="rate-count-com ">
          {Number(Math.ceil(Math.random() * 1000000)).toFixed(0)}&nbsp; Ratings
          &
        </span>
        <span className="review-count-com ">
          &nbsp; {Number(Math.ceil(Math.random() * 100000)).toFixed(0)}{" "}
          &nbsp;Reviews
        </span>
      </div>
      <div>
        <span className="discount-price-com ">
          {" "}
          Extra &nbsp; ₹{" "}
          {((Number(val.price.current.value * 84) / 100) * percent).toFixed(0)}
          &nbsp; Off{" "}
        </span>
      </div>
      <div className="price-case-com ">
        <span className="current-price-com ">
          ₹{" "}
          {(
            Number(val.price.current.value * 84) -
            (Number(val.price.current.value * 84) / 100) * percent
          ).toFixed(2)}
        </span>
        <span className="old-price-com ">
          <s> ₹ {val.price.current.value * 84}</s>
        </span>
        <span className="product-offer">{percent}% off</span>
      </div>

      <div className="offers-available">
        <h4 className="af-div"> Available Offers </h4>
        <section className="offer-container">
          {newArr.map((value: any, i: any, obj: any) => (
            <p className="offer-item" key={i}>
              <LocalOfferIcon /> Bank Offer 10% off on Axis Bank Credit Card and
              EMI Transactions, up to ₹1000, on orders of ₹5,000 and above
              <br /> <a href={"#"}>T&C</a>
            </p>
          ))}
          <span className="see-more" onClick={seeMoreOffers}>
            {size !== 20 ? `View  ${size / 2} more offers` : `View Less offers`}{" "}
            &nbsp;{" "}
          </span>
        </section>
      </div>
    </div>
  );
};

export default ProductDiscription;
