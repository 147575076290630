import React, { useState } from "react";
import "./LandingPage.css";
import TopNav from "../TopNav/TopNav";
import ProductNav from "../ProductNav/ProductNav";
import SideNav from "../SideNav/SideNav";
import ProductContainer from "../ProductContainer/ProductContainer";
import axios from 'axios';
import Footer from "../Footer/Footer";

const LandingPage = () => {

  // const [Compare, setCompare] = useState([]) as any
  // const [Compare,  show] = useState([]) as any


  return (
    <div className="page-landing">
      <ProductNav />
      <div className="product-showcase">
        <SideNav />
        <ProductContainer />
      </div>
    </div>
  );
};

export default LandingPage;
