import React from 'react'
import"./ProductDetails.css"
import ProductDiscription from './ProductDiscription'

const ProductDetails = () => {
  return (
    <div className='product-specification'>
      <ProductDiscription />
    </div>
  )
}

export default ProductDetails