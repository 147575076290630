/* eslint-disable jsx-a11y/img-redundant-alt */
import { KeyboardArrowDown } from "@mui/icons-material";
import React, { useEffect } from "react";
import "./TopSlider.css"

const TopSlider = () => {

  let data = ["https://elanpro.net/wp-content/uploads/2022/08/1-2048x672.jpg","https://dlcdnwebimgs.asus.com/gain/A85D9056-1C5D-467C-A1C3-276BC2D6BBFF","https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqdmXrtEIK3CSk2a9b2N3G_B-wUdb3VeJIe5xoOFPmyw&usqp=CAU&ec=48600112","https://images.samsung.com/is/image/samsung/assets/in/microsite/smart-convertible-refrigerator/home_kv_d.jpg?$ORIGIN_JPG$","https://rukminim1.flixcart.com/fk-p-flap/844/140/image/094484c7486aa03a.png?q=50","https://www.adgully.com/img/800/201805/realme.jpg"]


  useEffect(() => {
    let slideInterval=setInterval(nextButton,5000)

    return () => {
      clearInterval(slideInterval)
    }
  },[])
  let translateX = 0;
  const step = 100; // Adjust this value to change the slide width

  const prevButton = () => {

    const carouselContainer = document.querySelector(".image-slider-main") as HTMLImageElement;
    translateX += step;
    if (translateX > 0) {
      translateX = (-step * (carouselContainer?.children?.length-1 )) ;
    }
    carouselContainer.style.transition = `2s`;
    carouselContainer.style.transform = `translateX(${translateX}em)`;
  };

  const nextButton = () => {
    const carouselContainer = document.querySelector(".image-slider-main") as any;
    translateX -= step;
    if (translateX < (-step * (carouselContainer?.children.length-1)) ) {
      translateX = 0;
    }
    carouselContainer.style.transition = `2s`;
    carouselContainer.style.transform = `translateX(${translateX}em)`;
  };

  return (
    <div className="main-contain-main">
      <div className="carousel-main">
        <div className="carousel-container-main">
          <button onClick={prevButton} className="prev-button-main">
            <KeyboardArrowDown style={{ rotate: "90deg" }} className="icon-main" />
          </button>
          <div className="image-slider-main">
            {data.map((item: any, indx: any) => (
              <div key={indx} className="image-contain-main">
                <img
                  className="img-main"
                  src={item
                  }
                  alt="Image 1"
                  width={"100em"}
                />
              </div>
            ))}
          </div>
          <button onClick={nextButton} className="next-button-main">
            <KeyboardArrowDown style={{ rotate: "-90deg" }} className="icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopSlider;
