import React, { useContext, useEffect, useState } from "react";
import "./CartItem.css";
import { IconButton } from "@mui/material";
import { Add, Info, Remove } from "@mui/icons-material";
import { addDays, format } from "date-fns";
import { KartContext } from "../App";

const CartItem = (props: any) => {
  const { state, setData}:any= useContext(KartContext);
  let days = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
  let period = ["Tomorrow", "Today", ""];
  const [date, setDate] = useState() as any;
  const [data, setDatas] = useState(props.data) as any;

  const delivery = () => {
    let vals = period[Math.floor(Math.random() * period.length)];
    if (vals == "") {
      let date = new Date();
      let add = Math.floor(Math.random() * 13);
      date = addDays(date, add);
      let Deldate = format(date, "MMM dd");
      setDate(Deldate);
      return;
    }
    setDate(vals);
  };

  useEffect(() => {
    setDatas(props.data)
    delivery();
  }, []);

  const removeFormCart = (id: any) => {
    let data = [...state];
    let index = data.findIndex((v: any) => v.id === id);
    data.splice(index, 1);
    setData(data);
  };
  return (
    <div className="item-container">
      <div className="main-cart">
        <figure className="image-count">
          <img
            className="image-show"
            src={`https://${data.imageUrl}`}
            width={"140px"}
            height={"150px"}
            alt=""
          />
        </figure>
        <div className="item-data-cart">
          <h5>{data.name}</h5>
          <div style={{color:"grey"}}>{data.brandName}</div>
          <div style={{color:"grey"}}> Colour: {data.colour}</div>
          <div style={{color:"grey"}}>
            Colours Available: {data?.facetGroupings[0]?.products?.length}
          </div>
          <div className="price-case-cart">
            <span className="old-price">
              <s> ₹ {(data.price.current.value * 84).toFixed(0)}</s>
            </span>
            <span style={{ color: "black" }} className="current-price">
              ₹{" "}
              {(
                Number(data.price.current.value * 84) -
                (Number(data.price.current.value * 84) / 100) * props.percent
              ).toFixed(0)}
            </span>

            <span className="product-offer">{Number( props.percent) + Math.floor(Math.random()*15) }% Off</span>
          </div>
          <span className="applied-offer">
            {Math.floor(Math.random() * 10) +1} Applied offers
            <IconButton>
              <Info />
            </IconButton>{" "}
          </span>
        </div>
        <section className="delivery">
          Delivery By {date} &nbsp; {}
          {days[Math.floor(Math.random() * days.length)]}| <span>Free</span>{" "}
          <s>40</s>
        </section>
      </div>

      <div className="btn-group">
        <div className="count-contain">
          <IconButton disabled={props.count[data.id]<=1} className="icon-btn" onClick={e=>props.handleCount(e,-1,data.id)}>
            <Remove />
          </IconButton>
          <span className="cart-item-count">{props.count[data.id]}</span>
          <IconButton   className="icon-btn" onClick={e=>props.handleCount(e,1,data.id)}>
            <Add />
          </IconButton>
        </div>
        <section className="save-remove" >
               <button className="btn-below">SAVE FOR LATER</button>
               <button onClick={removeFormCart} className="btn-below"> REMOVE</button>
        </section>
      </div>
    </div>
  );
};

export default CartItem;
