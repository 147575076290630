import React from "react";
import TopNav from "../TopNav/TopNav";
import ProductNav1 from "../ProductNav1/ProductNav1";
import TopSlider from "../HorizontalCarousel/TopSlider";
import HCour from "../HorizontalCarousel/HCour";
import HCourType2 from "../HorizontalCarousel/HCourType2";

const MainLading = () => {
  return (
    <div className="main-landing">
      <ProductNav1 />
      <TopSlider />
      <HCour />
      <HCourType2/>
    </div>
  );
};

export default MainLading;
