import React, { useContext } from 'react'
import"./ViewComponent.css"
import ProductView from '../MainPageFile/ProductView'
import { ShoppingCartRounded } from '@mui/icons-material'
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { KartContext } from '../../App';
import { ProductContext } from '../SecondLanding/SecondLandingPage';
const ViewComponent = () => {
  const {state,setData}:any = useContext(KartContext)
  let data = [...state]
  let val:any = useContext(ProductContext);

  const addToCart = () => {
    data.push(val)
    setData(data)
    alert(val.name +"Successfully Added in Cart "  )
  }
  
  return (
      <div className='view-component'>
          <ProductView />
          <div className='button-container'>
              <button onClick={addToCart}  className='add-to-cart'><ShoppingCartRounded/> Add to Cart</button>
              <button className='buy-btn'><FlashOnIcon/> Buy Now</button>
          </div>
    </div>
  )
}

export default ViewComponent