import React from "react";
import TopNav from "../TopNav/TopNav";
import ProductNav from "../ProductNav/ProductNav";
import Footer from "../Footer/Footer";
import CompareItems from "../ComapreItems/CompareItems";

const LandingThree = () => {
  return (
    <div className="page-landing">
      <ProductNav />
      <div className="product-compare">
        <CompareItems />
      </div>
    </div>
  );
};

export default LandingThree;
