/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./HcourType2.css";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Link } from "react-router-dom";

let vals = `[{"id":202600742,"name":"Dr Martens 1461 bex platform 3-eye shoes in black","price":{"current":{"value":130,"text":"$130.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"Black","colourWayId":202600743,"brandName":"Dr Martens","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":117615594,"productType":"Product","url":"dr-martens/dr-martens-1461-bex-platform-3-eye-shoes-in-black/prd/202600742?clr=black&colourWayId=202600743","imageUrl":"images.asos-media.com/products/dr-martens-1461-bex-platform-3-eye-shoes-in-black/202600742-1-black","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[{"products":[{"productId":20540568},{"productId":202600742}],"type":"SupplierColour"}],"advertisement":null}
,{"id":202896495,"name":"Red Tape chunky lace up shoes with gum sole in black leather","price":{"current":{"value":66,"text":"$66.00"},"previous":{"value":null,"text":""},"rrp":{"value":91,"text":"$91.00"},"isMarkedDown":false,"isOutletPrice":true,"currency":"USD"},"colour":"Black","colourWayId":202896505,"brandName":"Red Tape","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":118906103,"productType":"Product","url":"red-tape/red-tape-chunky-lace-up-shoes-with-gum-sole-in-black-leather/prd/202896495?clr=black&colourWayId=202896505","imageUrl":"images.asos-media.com/products/red-tape-chunky-lace-up-shoes-with-gum-sole-in-black-leather/202896495-1-black","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":202961881,"name":"Nike Air force 1 '07 sneakers in white and photo blue","price":{"current":{"value":110,"text":"$110.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"White","colourWayId":202961884,"brandName":"Nike","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":119204088,"productType":"Product","url":"nike/nike-air-force-1-07-sneakers-in-white-and-photo-blue/prd/202961881?clr=white&colourWayId=202961884","imageUrl":"images.asos-media.com/products/nike-air-force-1-07-sneakers-in-white-and-photo-blue/202961881-1-white","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":203120887,"name":"French Connection hike boots in charcoal","price":{"current":{"value":84,"text":"$84.00"},"previous":{"value":null,"text":""},"rrp":{"value":182,"text":"$182.00"},"isMarkedDown":false,"isOutletPrice":true,"currency":"USD"},"colour":"Dk charcoal","colourWayId":203120889,"brandName":"French Connection","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":119668497,"productType":"Product","url":"french-connection/french-connection-hike-boots-in-charcoal/prd/203120887?clr=dk-charcoal&colourWayId=203120889","imageUrl":"images.asos-media.com/products/french-connection-hike-boots-in-charcoal/203120887-1-dkcharcoal","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":203291219,"name":"Nike Free Run 5.0 sneakers in white","price":{"current":{"value":100,"text":"$100.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"MID BLUE","colourWayId":203291220,"brandName":"Nike Running","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":120540609,"productType":"Product","url":"nike-running/nike-free-run-50-sneakers-in-white/prd/203291219?clr=mid-blue&colourWayId=203291220","imageUrl":"images.asos-media.com/products/nike-free-run-50-sneakers-in-white/203291219-1-midblue","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":203356826,"name":"Koi Footwear contrast detail sneakers in black and yellow","price":{"current":{"value":66,"text":"$66.00"},"previous":{"value":null,"text":""},"rrp":{"value":91,"text":"$91.00"},"isMarkedDown":false,"isOutletPrice":true,"currency":"USD"},"colour":"Black","colourWayId":203356827,"brandName":"Koi Footwear","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":120651553,"productType":"Product","url":"koi-footwear/koi-footwear-contrast-detail-sneakers-in-black-and-yellow/prd/203356826?clr=black&colourWayId=203356827","imageUrl":"images.asos-media.com/products/koi-footwear-contrast-detail-sneakers-in-black-and-yellow/203356826-1-black","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":203360148,"name":"Nike Air Pegasus 83 sneakers in white","price":{"current":{"value":95,"text":"$95.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"WHITE","colourWayId":203360149,"brandName":"Nike","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":120653313,"productType":"Product","url":"nike/nike-air-pegasus-83-sneakers-in-white/prd/203360148?clr=white&colourWayId=203360149","imageUrl":"images.asos-media.com/products/nike-air-pegasus-83-sneakers-in-white/203360148-1-white","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":203507494,"name":"Nike Air Max 97 OG sneakers in gold and red","price":{"current":{"value":185,"text":"$185.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"GOLD","colourWayId":203507503,"brandName":"Nike","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":121208349,"productType":"Product","url":"nike/nike-air-max-97-og-sneakers-in-gold-and-red/prd/203507494?clr=gold&colourWayId=203507503","imageUrl":"images.asos-media.com/products/nike-air-max-97-og-sneakers-in-gold-and-red/203507494-1-gold","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":203628988,"name":"New Balance 57/40 o in light gray","price":{"current":{"value":94.99,"text":"$94.99"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"LIGHT GRAY","colourWayId":203629026,"brandName":"New Balance","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":121808721,"productType":"Product","url":"new-balance/new-balance-57-40-sneakers-in-light-gray/prd/203628988?clr=light-gray&colourWayId=203629026","imageUrl":"images.asos-media.com/products/new-balance-57-40-sneakers-in-light-gray/203628988-1-lightgrey","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":203665647,"name":"Nike Air Max Alpha sneakers in gray and red - gray","price":{"current":{"value":90,"text":"$90.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"Gray","colourWayId":203665660,"brandName":"Nike Training","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":122018352,"productType":"Product","url":"nike-training/nike-air-max-alpha-sneakers-in-gray-and-red-gray/prd/203665647?clr=gray&colourWayId=203665660","imageUrl":"images.asos-media.com/products/nike-air-max-alpha-sneakers-in-gray-and-red-gray/203665647-1-grey","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":204298977,"name":"AllSaints Mem low top sneakers in black","price":{"current":{"value":95,"text":"$95.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"Black","colourWayId":204298985,"brandName":"AllSaints","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":126452240,"productType":"Product","url":"allsaints/allsaints-mem-low-top-sneakers-in-black/prd/204298977?clr=black&colourWayId=204298985","imageUrl":"images.asos-media.com/products/allsaints-mem-low-top-sneakers-in-black/204298977-1-black","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":204298992,"name":"AllSaints Klip low top sneakers in white","price":{"current":{"value":179,"text":"$179.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"WHITE","colourWayId":204298994,"brandName":"AllSaints","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":126452239,"productType":"Product","url":"allsaints/allsaints-klip-low-top-sneakers-in-white/prd/204298992?clr=white&colourWayId=204298994","imageUrl":"images.asos-media.com/products/allsaints-klip-low-top-sneakers-in-white/204298992-1-white","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}
,{"id":204426746,"name":"Vans Lowland sneakers in desert camo","price":{"current":{"value":90,"text":"$90.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"BEIGE","colourWayId":204426747,"brandName":"Vans","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":127183522,"productType":"Product","url":"vans/vans-lowland-sneakers-in-desert-camo/prd/204426746?clr=beige&colourWayId=204426747","imageUrl":"images.asos-media.com/products/vans-lowland-sneakers-in-desert-camo/204426746-1-beige","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[{"products":[{"productId":204426746},{"productId":204456568},{"productId":204457072}],"type":"SupplierColour"}],"advertisement":null}
]`;

const HCourType2 = () => {
  let data = JSON.parse(vals) as Array<any>;

  let translateX = 0;
  const step = 45; // Adjust this value to change the slide width

  const prevButton = () => {
    const carouselContainer = document.querySelector(".image-slider-2") as any;
    translateX += step;
    if (translateX > 0) {
      translateX = (-step * (carouselContainer?.children?.length - 3)) / 2;
    }
    carouselContainer.style.transition = `1s`;
    carouselContainer.style.transform = `translateX(${translateX}em)`;
  };

  const nextButton = () => {
    const carouselContainer = document.querySelector(".image-slider-2") as any;
    translateX -= step;
    if (translateX < (-step * (carouselContainer?.children.length - 2)) / 2) {
      translateX = 0;
    }
    carouselContainer.style.transition = `1s`;
    carouselContainer.style.transform = `translateX(${translateX}em)`;
  };
  return (
    <div className="main-contain-2">
      <div className="carousel-2">
        <div className="item-overview-2">
          <Link to={"/landing"}>
            {" "}
            <button className="view-all-2">VIEW ALL</button>
          </Link>
        </div>
        <div className="carousel-container-2">
          <button onClick={prevButton} className="prev-button-2">
            <KeyboardArrowDown style={{ rotate: "90deg" }} className="icon-2" />
          </button>
          <div className="image-slider-2">
            {data.map((item: any, indx: any) => (
              <div key={indx} className="image-contain-2">
                <img
                  className="img-2"
                  src={`https://${item.imageUrl}`}
                  alt="Image 1"
                />
                <div className="item-desc-carousel-2">{item.name}</div>
                <div className="item-desc-carousel-2">
                  From ${item.price.current.value}
                </div>
                <div className="item-desc-carousel-2">{item.brandName}</div>
              </div>
            ))}
          </div>
          <button onClick={nextButton} className="next-button-2">
            <KeyboardArrowDown style={{ rotate: "-90deg" }} className="icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HCourType2;
