import { StarRateSharp } from "@mui/icons-material";
import React, { useState } from "react";



const ValueIterate = (props:any) => {
  const [percent, setPercent] = useState(
    (Math.random() * 90).toFixed(0)
    ) as any;
    
    const { product } = props;
  let reviewCount = Number(Math.ceil(Math.random() * 10000)).toFixed(0);
  return (
    <div className="item-values">
      <div className="item-value-image">
        <img
          className="product-image-item"
          src={`https://${product.imageUrl}`}
          alt={""}
        />
      </div>
      <div className="item-value">
        <a href={product.url} title={product.name} className="product-details-item">
          {product.name}
        </a>
      </div>
      <div className="item-value">
        <div className="review-container-item">
        <span className="product-rate-item">
          {Number(Math.random() * (3 - 5 + 1) + 3).toFixed(1)}
          <StarRateSharp className="star" />
        </span>
          <span className="rate-count-item">
            &nbsp; Ratings (
            {Number(Math.ceil(Math.random() * 1000000)).toFixed(0)}) &nbsp; &
            &nbsp;
          </span>
          <span className="review-count-item">Reviews ({reviewCount})</span>
        </div>
      </div>
      <div className="item-value">{product.brandName}</div>
      <div className="item-value">{"Free Delivery"}</div>
      <div className="item-value">
        <div className="price-case-item">
          <span style={{ color: "black" }} className="current-price-item">
            ₹
            {(
              Number(product.price.current.value * 84) -
              (Number(product.price.current.value * 84) / 100) * percent
            ).toFixed(0)}
          </span>
          <span className="old-price-item">
            <s> ₹ {(product.price.current.value * 84).toFixed(0)}</s>
          </span>
          <span className="product-offer-item">{percent}% off</span>
        </div>
      </div>
     <div className="item-value">{Math.floor((Math.random()*10)).toFixed(0)} Bank offers</div>
      <div className="item-value">{product.colour}</div>
      <div className="item-value">{product.facetGroupings[0]?.products.length||0}</div>
      <div className="item-value">{product.isSellingFast?"Yes":"No"}</div> 
    </div>
  );
};

export default ValueIterate;
