import React from "react";
import "./MoreDropDown.css"
import { CardTravel, Home, Light, PaymentSharp, Search, Settings } from "@mui/icons-material";
import { Toolbar } from "@mui/material";

let arr = [{menuName:"Search"}, {menuName:"Home"} ,{menuName:"Payment"}, {menuName:"Settings"}, {menuName:"Products"}];
let iconArr=[<Search/>,<Home/>,<PaymentSharp/>,<Settings/>,<CardTravel/>]
const MoreDropDown = (props:any) => {
  
  return (
    <div className="menu-drop">
      {arr.map((value, index, obj) => (
        <div className="menu-item" key={index}>
          <span style={{display:!props.noIcon?"":"none"}} className="menu-icon">
            {iconArr[index]}</span>
          {value.menuName}
        </div>
      ))}
    </div>
  );

};

export default MoreDropDown;
