import React from "react";
import "./CompareItems.css";
import KeyIterate from "./KeyIterate";
import ValueIterate from "./ValueIterate";
import { useLocation } from "react-router-dom";

const data = `[{"id":202600742,"name":"Dr Martens 1461 bex platform 3-eye shoes in black","price":{"current":{"value":130,"text":"$130.00"},"previous":{"value":null,"text":""},"rrp":{"value":null,"text":""},"isMarkedDown":false,"isOutletPrice":false,"currency":"USD"},"colour":"Black","colourWayId":202600743,"brandName":"Dr Martens","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":117615594,"productType":"Product","url":"dr-martens/dr-martens-1461-bex-platform-3-eye-shoes-in-black/prd/202600742?clr=black&colourWayId=202600743","imageUrl":"images.asos-media.com/products/dr-martens-1461-bex-platform-3-eye-shoes-in-black/202600742-1-black","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[{"products":[{"productId":20540568},{"productId":202600742}],"type":"SupplierColour"}],"advertisement":null}
,{"id":202896495,"name":"Red Tape chunky lace up shoes with gum sole in black leather","price":{"current":{"value":66,"text":"$66.00"},"previous":{"value":null,"text":""},"rrp":{"value":91,"text":"$91.00"},"isMarkedDown":false,"isOutletPrice":true,"currency":"USD"},"colour":"Black","colourWayId":202896505,"brandName":"Red Tape","hasVariantColours":false,"hasMultiplePrices":false,"groupId":null,"productCode":118906103,"productType":"Product","url":"red-tape/red-tape-chunky-lace-up-shoes-with-gum-sole-in-black-leather/prd/202896495?clr=black&colourWayId=202896505","imageUrl":"images.asos-media.com/products/red-tape-chunky-lace-up-shoes-with-gum-sole-in-black-leather/202896495-1-black","videoUrl":null,"isSellingFast":false,"sponsoredCampaignId":null,"facetGroupings":[],"advertisement":null}]
`;
const CompareItems = () => {
  
  let { state } = useLocation();

  return (
    <div className="comapare-items">
      <KeyIterate />
      {state.map((value: any, i: any) => (
        <ValueIterate key={i} product={value} />
      ))}
    </div>
  );
};

export default CompareItems;
