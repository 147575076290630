import React, { createContext, useState } from "react";
import "./App.css";
import RouterComponent from "./Router/RouterComponent";
import ProductNav from "./ProductNav/ProductNav";
import TopNav from "./TopNav/TopNav";
import Footer from "./Footer/Footer";
import HCour from "./HorizontalCarousel/HCour";
import MainLading from "./MainLanding/MainLading";

export const KartContext = createContext({});

function App() {
  const [data, setData] = useState([]) as any;

  let manageCart = (data: any) => {
    let val = [...(new Set(data) as any)];
    setData(val);
  };

  console.log(" items in cart-----> ", data);
  return (
    <div className="app">
      <KartContext.Provider value={{ state: data, setData: manageCart }}>
        <TopNav />
        <RouterComponent />
        <Footer />
      </KartContext.Provider>
    </div>
  );
}

export default App;
