import React from 'react'
import "./Footer.css"
import { GitHub, Instagram, LinkedIn, Mail, WhatsApp } from '@mui/icons-material'

const Footer = () => {
  return (
    <footer className='footer'>

      <div className='about-card align'>
        <h5 className="footer-title"> About</h5>
        <ul>
          <li><a href='#'>About us</a></li>
          <li><a href='#'>Carreer</a></li>
          <li><a href='#'>Contact us</a></li>
          <li><a href='#'>Stories</a></li>
          <li><a href='#'>Informations</a></li>
        </ul>

      </div>
      <div className='help-card align'>
        <h5 className="footer-title" >Help</h5>
        <ul>
          <li><a href='#'>Payments</a></li>
          <li><a href='#'>Shippings</a></li>
          <li><a href='#'>Cancellation</a></li>
          <li><a href='#'>FAQ</a></li>
          <li><a href='#'>Repoat 1</a></li>
        </ul>
      </div>
      <div className='contact-card align'>
        <h5 className="footer-title">Contact</h5>
        <ul>
          <li><a href='#'>Phone</a></li>
          <li><a href='#'>Mail</a></li>
          <li><a href='#'>Address</a></li>
          <li><a href='#'>WhatsApp</a></li>
          <li><a href='#'>Address2</a></li>
          <li><a href='#'>Mobile</a></li>
        </ul>
      </div>
      <div className='customer-policy align' >
        <h5 className="footer-title">Customer Policy</h5>
        <ul>
          <li><a href='#'>Return Policy</a></li>
          <li><a href='#'>Security</a></li>
          <li><a href='#'>Privacy</a></li>
          <li><a href='#'>Terms And Condition</a></li>
          <li><a href='#'>Warranty</a></li>
          <li><a href='#'>Replacemant</a></li>
        </ul>
      </div>
      <div className='social-container'>
        <section className='social-icons whatsapp'><a href='https://www.instagram.com/__itz_mee_tamil__/'><WhatsApp/></a> </section>
        <section className='social-icons instagram'> <a href='https://www.instagram.com/__itz_mee_tamil__/'><Instagram/></a></section>
        <section className='social-icons linkedin'><LinkedIn/></section>
        <section className='social-icons github'> <GitHub/></section>
        <section className='social-icons mail'> <Mail/></section>
      </div>
    </footer>
  )
}

export default Footer