import React from "react";
import CartComponent from "./CartComponent";
import ProductNav from "../ProductNav/ProductNav";

const CartLanding = () => {
  return (
    <div className="page-landing">
      <ProductNav />
      <div className="product-compare">
        <CartComponent />
      </div>
    </div>
  );
};

export default CartLanding;
