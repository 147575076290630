/* eslint-disable react/jsx-no-undef */
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "../LandingPage/LandingPage";
import SecondLandingPage from "../ProductView/SecondLanding/SecondLandingPage";
import LoginPage from "../LoginPage/LoginPage";
import LandingThree from "../LandingThree/LandingThree";
import CartLanding from "../CartComponent/CartLanding";
import MainLading from "../MainLanding/MainLading";

const RouterComponent = () => {
  return (
    <div>
          <Routes>
            <Route path="/" index element={<MainLading/>} />
            <Route path="/landing" index element={<LandingPage />} />
            <Route path="/productview" element={<SecondLandingPage />} />
            {/* <Route path="login" element={<LoginPage />} /> */}
            <Route path="compare" element={<LandingThree/>} />
            <Route path="/cart" element={<CartLanding/>} />
          </Routes>
    </div>
  );
};

export default RouterComponent;
