import React, { useContext } from "react";
import "./TopNav.css";
import MoreDropDown from "../ZonekartComps/MoreDropDown";
import { Button, IconButton} from "@mui/material";
import {  KeyboardArrowUp, Search, ShoppingCartRounded} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { KartContext } from "../App";


let btnStyle = {
  borderRadius: "0",
  border: "0",
  fontSize: "14px",
  color: "white"
}
let inp = {
  height: "2.4rem",
  border: 0,
  width: "24rem",
  maxWidth: "100%",
  outline: "none",
  outlineWidth: 0,
}
const TopNav = () => {

  let {state,manageCart}:any=useContext(KartContext)
  return (
    <header className="top-nav">
  
      <Button  size="large" style={{ backgroundColor:"transparent", display: "flex", flexDirection:"column",flexFlow:"column",...btnStyle,textTransform:"none"}} className="nav-logo">
          <i style={{  fontSize: "14px",fontWeight:900}}>Zonekart</i>
        <small>
          <i style={{ fontWeight: 700 ,top:"0"}}>
            Buy <span style={{ fontWeight: 700, color: "gold" }}>Plus+</span>
          </i>
        </small>
      </Button>
      <div className="top-search nav-focus"  >
        <input
          type="search"
          autoCorrect="true"
          autoComplete="true"
          placeholder="Search product"
          style={inp}
        /><IconButton style={{...btnStyle,backgroundColor:"white"}}><Search color="primary"/></IconButton>
      </div>
      <button className="nav-loginbtn nav-hover">Login</button>
      <Button  style={{...btnStyle,textTransform:"none"}} className="more-btn">
        Become a Seller
      </Button>
      <Button  style={{textTransform:"none",...btnStyle}} className="more-btn">
        More <KeyboardArrowUp className="rotate-arrow" style={{rotate:"180deg",top:"10px"}}/>
        <div  className="more-dropdown">
          <MoreDropDown />
        </div>
      </Button>
      <Link to="/cart" >
      <Button style={{textTransform:"none" ,...btnStyle }}>
          <span style={{display: state.length?"":"none"}} className="items-in-cart"> {state.length}</span> 
        <ShoppingCartRounded />
        <span style={{ fontSize: "14px", marginLeft: "10px" }}>Cart</span> 
      </Button>
      </Link>
    </header>
    
  );
};

export default TopNav;
