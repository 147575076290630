import React, { useContext, useEffect, useState } from "react";
import "./CartComponent.css";
import { Button } from "@mui/material";
import CartItem from "./CartItem";
import { KartContext } from "../App";

const CartComponent = () => {
  const { state }: any = useContext(KartContext);

  const [price, setPrice] = useState({
    total: 0,
    dicount: 0,
    current: 0,
    usd: 0,
    save:0
  });
  const [cartObj, setCartObj] = useState({}) as any;
  let [map, setMap] = useState(new Map<string, number>());
  const [percent, setPercent] = useState(
    (Math.random() * 80).toFixed(0)
  ) as any;


  useEffect(() => {
    let obj: any = {};
    let maps = new Map<string, number>();
    state?.forEach((v: any) => {
      if (maps.has(v.id)) {
        let cost = maps.get(v.id) ?? 0;
        maps.set(v.id, cost + Number(v.price.current.value));
      } else {
        maps.set(v.id, Number(v.price.current.value));
      }
      obj = { ...obj, [v.id]: 1 };
    });
    console.log(maps);
    setMap(maps);
    setCartObj(() => {
      calAmount(maps, obj);
      return obj;
    });
  }, []);

  let calAmount = (maps: any, obj: any) => {
    let total = 0;
    let usd = 0;
    let discount = 0;
    let cost = 0;
    let save = 0;
    maps.forEach((v: any, k: any, map: any) => {
      console.log(k, obj[k]);
      total += v * obj[k] ?? 1;
      usd = v * obj[k] ?? 1;
    });
    discount = Math.floor(total * 84 - ((total * percent) / 100) * percent);
    total = total * 84;
    cost = total - discount;
    setPrice({
      ...price,
      total: Math.floor(total),
      dicount:  Math.floor(discount-1500),
      current:  Math.floor(cost+1500),
      usd: usd,
    });
  };

  const handleCount = (e: any, val: any = 1, key: any) => {
    let value = cartObj[key] + val;
    setCartObj({ ...cartObj, [key]: value });
    calAmount(map, cartObj);
  };

  return (
    <div className="cart-comp">
      <div className="item-desc">
        <div className="zonekart"> ZoneKart ({state.length})</div>
        <div className="address-container">
          <section>Address</section>
          <Button
            style={{ backgroundColor: "skyblsue !important" }}
            color={"primary"}
          >
            Change
          </Button>
        </div>
        <>
          {state.map((data: any, index: any) => (
            <div key={index} className="items-cart">
              <CartItem
                percent={percent}
                data={data}
                count={cartObj}
                handleCount={handleCount}
              />
            </div>
          ))}
        </>
        <div className="place-order">
          <button className="place-o-btn">Place Order</button>
        </div>
      </div>
      <div className="item-price">
        <h4 className="cart-price-head ">PRICE DETAILS </h4>
        <div className="cart-price ">
          Price ({Object.values(cartObj).length} items){" "}
          <span>₹{price.total}</span>{" "}
        </div>
        <div className="cart-price ">
          Discount <span className="col">₹{price.dicount}</span>{" "}
        </div>
        <div className="cart-price ">
          Delivery Charges <span className="col">Free</span>{" "}
        </div>
        <div className="cart-price ">
          Secured Packaging Fee<span>₹{price.usd}</span>{" "}
        </div>
        <div className="cart-price total ">
          Total Amount <span>₹{price.current}</span>{" "}
        </div>
        <div className="cart-price col">You will save ₹{price.dicount} on this order</div>
      </div>
    </div>
  );
};

export default CartComponent;
